/**
 * Global Styles - App
 *
 * Any global styles overrides or additional styles can be included in this file
 */
import React from 'react';
import { createGlobalStyle, Normalize } from '@smooth-ui/core-sc';
import loriGlobalStyles from '_platform/src/theme/globalStyles';

export const GlobalStylesApp = createGlobalStyle`
   ${loriGlobalStyles}

   body {
     color: ${props => props.theme.defaultFontColor || '#4b525b'};
   }

   h1 {
     font-weight: normal;
   }

   a {
     color: ${props => props.theme.linkColor || '#062333'};
     text-decoration: none;

     &:hover {
       text-decoration: underline;
     }
   }

   table {
     width: 100%;

     thead {
       font-weight: bold;

       td {
         padding-bottom: .5em;
       }
     }

     tbody td {
       border-bottom: 1px solid rgba(84, 112, 124, 0.25); /* #4b525b */
       padding: .5em .5em .5em 0;
     }

     tbody tr:hover {
       background-color: rgba(84, 112, 124, 0.1); /* #4b525b */
     }
   }

   div.rt-tr {
     align-items: center;
   }

   form label {
     font-weight: bold;
   }

   form .rs__control {
     border-radius: .25rem;
   }

   .Toastify {
     .Toastify__toast {
       border-radius: ${props => props.theme.borderRadius || 0};
       min-height: 50px;
     }
     .Toastify__toast-body {
       font-size: 0.875rem;
     }

     .Toastify__toast--default {
       background: ${props => props.theme.light};
       color: ${props => props.theme.themeColor || '#4b525b'};
     }
     .Toastify__toast--info {
       background: ${props => props.theme.info}
     }
     .Toastify__toast--success {
       background: ${props => props.theme.success}
     }
     .Toastify__toast--warning {
       background: ${props => props.theme.warning}
     }
     .Toastify__toast--error {
       background: ${props => props.theme.error || props.theme.danger}
     }

     .Toastify__close-button {
       font-weight: normal;
     }
     .Toastify__close-button--default {
       color: ${props => props.theme.themeColor || '#4b525b'};
       opacity: 0.7;
     }

     .Toastify__progress-bar {
       height: 4px;
     }
     .Toastify__progress-bar--default {
       background: ${props => props.theme.primary}
     }
   }

   code {
     background-color: rgba(27,31,35,.05);
     border-radius: 3px;
     font-size: 85%;
     margin: 0;
     padding: .2em .4em;
   }

   @media print {
     nav {
       display: none;
     }
   }
 `;

const StylesApp = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStylesApp />
  </React.Fragment>
);

export default StylesApp;
